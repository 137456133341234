var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('el-container',{staticClass:"container"},[_c('el-aside',{staticClass:"aside",attrs:{"width":"140px"}},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/images/logo.png"),"alt":"logo"}}),_c('el-tabs',{staticStyle:{"width":"100%","margin-top":"20px"},attrs:{"tab-position":"left"},on:{"tab-click":_vm.scrollToCategory},model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},_vm._l((_vm.navArr),function(item,index){return _c('el-tab-pane',{key:index,attrs:{"label":item}})}),1)],1),_c('el-container',[_c('el-header',{staticStyle:{"height":"auto"}},[_c('v-header')],1),_c('el-main',[_c('el-backtop',{attrs:{"target":"#main"}}),_c('main',{ref:"main",staticClass:"main",attrs:{"id":"main"},on:{"scroll":_vm.watchScrollTop}},[_c('div',{ref:"search",staticClass:"search"},[_c('el-input',{staticClass:"search-input",attrs:{"autofocus":"","placeholder":_vm.searchSites[_vm.searchIndex].text,"size":"normal"},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.jumpSearch.apply(null, arguments)}},model:{value:(_vm.searchVal),callback:function ($$v) {_vm.searchVal=$$v},expression:"searchVal"}},[_c('i',{staticClass:"el-icon-search el-input__icon icon",attrs:{"slot":"suffix"},on:{"click":_vm.jumpSearch},slot:"suffix"})]),_c('ul',{staticClass:"search-type"},_vm._l((_vm.searchSites),function(item,index){return _c('li',{key:index,staticClass:"item-box"},[_c('div',{class:[
                    'search-item',
                    index === _vm.searchIndex ? 'selected' : ''
                  ],on:{"click":function($event){_vm.searchIndex = index}}},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{class:[
                    'setting-default',
                    index === _vm.defaultSearchIndex
                      ? 'setting-default-selected'
                      : ''
                  ],on:{"click":function($event){return _vm.settingDefault(index)}}},[_vm._v(" 设置为默认 ")])])}),0)],1),_c('div',{staticClass:"nav-content"},_vm._l((_vm.navDataArr),function(item,index){return _c('div',{key:index,ref:"item",refInFor:true},[_c('h1',{staticClass:"title"},[_c('i',{staticClass:"el-icon-price-tag icon"}),_vm._v(_vm._s(_vm.navArr[index])+" ")]),_c('el-row',{staticClass:"item",attrs:{"gutter":20}},_vm._l((item),function(item_sub,index_sub){return _c('el-col',{key:index_sub,staticClass:"item-sub-wrap",attrs:{"lg":4,"sm":6,"xs":12,"title":item_sub.description
                      ? item_sub.description
                      : item_sub.title},nativeOn:{"click":function($event){return _vm.jumpLink(index, index_sub)}}},[_c('div',{staticClass:"item-sub"},[_c('el-image',{staticClass:"img-item",attrs:{"src":item_sub.imageSrc,"title":item_sub.title,"lazy":""}},[_c('div',{staticClass:"image-slot",attrs:{"slot":"error"},slot:"error"},[_c('i',{staticClass:"el-icon-picture-outline"})])]),_c('span',{staticClass:"text"},[_vm._v(_vm._s(item_sub.title))])],1)])}),1)],1)}),0),_c('div',{staticClass:"bsz"},[_c('span',{attrs:{"id":"busuanzi_container_site_pv"}},[_vm._v("本站总访问量"),_c('span',{attrs:{"id":"busuanzi_value_site_pv"}}),_vm._v("次")]),_c('span',{attrs:{"id":"busuanzi_container_site_uv"}},[_vm._v(" 本站访客数"),_c('span',{attrs:{"id":"busuanzi_value_site_uv"}}),_vm._v("人 ")])])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }