let arr = [
  "在你发怒的时候，要紧闭你的嘴，免得增加你的怒气。——苏格拉底",
  "生气是拿别人做错的事来惩罚自己。",
  "生活中若没有朋友，就像生活中没有阳光一样。",
  "明天的希望会让我们忘了今天的痛苦。",
  "生活若剥去了理想、梦想、幻想，那生命便只是一堆空架子。",
  "发光并非太阳的专利，你也可以发光，真的。",
  "愚者用肉体监视心灵，智者用心灵监视肉体。",
  "贪婪是最真实的贫穷，满足是最真实的财富。",
  "获致幸福的不二法门是珍视你所拥有的、遗忘你所没有的。",
  "你可以用爱得到全世界，你也可以用恨失去全世界。",
  "人的价值，在遭受诱惑的一瞬间被决定。",
  "年轻是我们唯一拥有权利去编织梦想的时光。",
  "青春一经“典当”，永不再赎。",
  "没有了爱的语言，所有的文字都是乏味的。",
  "真正的爱，应该超越生命的长度、心灵的宽度、灵魂的深度。",
  "人一旦觉悟，就会放弃追寻身外之物，而开始追寻内心世界的真正财富。",
  "只要有信心，人永远不会挫败。",
  "不论你在什么时候开始，重要的是开始之后就不要停止。",
  "不论你在什么时候结束，重要的是结束之后就不要悔恨。",
  "人若软弱就是自己最大的敌人。",
  "人若勇敢就是自己最好的朋友。",
  "“不可能”只存在于蠢人的字典里。",
  "抱最大的希望，为最大的努力，做最坏的打算。",
  "家！甜蜜的家！！天下最美好的莫过于家！",
  "游手好闲会使人心智生锈。",
  "每一件事都要用多方面的角度来看它。",
  "有理想在的地方，地狱就是天堂。",
  "有希望在的地方，痛苦也成欢乐。",
  "所有的失败，与失去自己的失败比起来，更是微不足道。",
  "上帝从不埋怨人们的愚昧，人们却埋怨上帝的不公。",
  "美好的生命应该充满期待、惊喜和感激。",
  "世上最累人的事，莫过于虚伪的过日子。",
  "觉得自己做的到和做不到，其实只在一念之间。",
  "第一个青春是上帝给的；第二个的青春是靠自己努力的。",
  "少一点预设的期待，那份对人的关怀会更自在。",
  "思想如钻子，必须集中在一点钻下去才有力量。",
  "人只要不失去方向，就不会失去自己。",
  "如果你曾歌颂黎明，那么也请你拥抱黑夜。",
  "问候不一定要慎重其事，但一定要真诚感人。",
  "未经一番寒彻骨，哪得梅花扑鼻香。",
  "当你能飞的时候就不要放弃飞。",
  "当你能梦的时候就不要放弃梦。",
  "生命太过短暂，今天放弃了明天不一定能得到。",
  "天才是百分之一的灵感加上百分之九十九的努力。",
  "人总是珍惜未得到的，而遗忘了所拥有的。",
  "快乐要懂得分享，才能加倍的快乐。",
  "自己要先看得起自己，别人才会看得起你。",
  "一个今天胜过两个明天。",
  "要铭记在心：每天都是一年中最美好的日子。",
  "乐观者在灾祸中看到机会，悲观者在机会中看到灾祸。",
  "有勇气并不表示恐惧不存在，而是敢面对恐惧、克服恐惧。",
  "肯承认错误则错已改了一半。",
  "明天是世上增值最快的一块土地，因它充满了希望。",
  "理想的路总是为有信心的人预备着。",
  "所有欺骗中，自欺是最为严重的。",
  "人生最大的错误是不断担心会犯错。",
  "经验是由痛苦中粹取出来的。",
  "用最少的悔恨面对过去。",
  "用最少的浪费面对现在。",
  "用最多的梦想面对未来。",
  "快乐不是因为拥有的多而是计较的少。",
  "你的选择是做或不做，做不一定会成功，但不做就永远不会有机会。",
  "如你想要拥有完美无暇的友谊，可能一辈子找不到朋友。",
  "不如意的时候不要尽往悲伤里钻，想想有笑声的日子吧！",
  "把自己当傻瓜，不懂就问，你会学的更多。",
  "要纠正别人之前，先反省自己有没有犯错。",
  "因害怕失败而不敢放手一搏，永远不会成功。",
  "要克服生活的焦虑和沮丧，得先学会做自己的主人。",
  "你不能左右天气，但你能转变你的心情。",
  "孤单寂寞与被遗弃感是最可怕的贫穷。",
  "漫无目的的生活就像出海航行而没有指南针。",
  "好好扮演自己的角色，做自己该做的事。",
  "一切伟大的行动和思想，都有一个微不足道的开始。",
  "得意时应善待他人，因为你失意时会需要他们。",
  "学做任何事得按部就班，急不得。",
  "勿以恶小而为之，勿以善小而不为。",
  "道德修养能达到的最高价段，是认识到我们应该控制我们的思想。--达尔文",
  "多行不义， 必自毙。——《左传》",
  "知者不惑，仁者不忧，勇者不惧。——《论语》",
  "君子坦荡荡，小人常戚戚。——《论语》",
  "知人者智，自知者明。——《老子》",
  "诚无悔，恕无怨，和无仇，忍无辱。——宋《省心录》",
  "君子成人之美，不成人之恶。——《论语》",
  "利人乎即为，不利人乎即止。——《 墨子》",
  "阴谋陷害别人的人，自己会首先遭到不幸。——伊索",
  "静以修身，俭以养德。——诸葛亮",
  "夫妇和，而后家道成。——清·程允中",
  "最可怕的敌人，就是没有坚强的信念。——罗曼·罗兰",
  "你若要喜爱你自己的价值，你就得给世界创造价值。 ——歌德",
  "君子赠人以言，庶人赠人以财。——荀况",
  "受惠的人，必须把那恩惠常藏心底，但是施恩的人则不可记住它。--西塞罗",
  "应当在朋友正是困难的时候给予帮助，不可在事情无望之后再说闲话。伊索",
  "谁若游戏人生，他就一事无成；谁不主宰自己，永远是一个奴隶。——歌德",
  "每天告诉自己一次，“我真的很不错”。"
];
export default arr;
